import axios from 'axios';
import { ref, useContext } from '@nuxtjs/composition-api';
import type { DAMImage, DAMImageMetadata } from '@amplience/types';
import { UseDAMImage } from './useDAMImage';
import { Logger } from '~/helpers/logger';

export function useDAMImage(): UseDAMImage {
  const loading = ref<boolean>(false);
  const image = ref<DAMImage | null>(null);
  const metadata = ref<DAMImageMetadata | null>(null);
  const { $amplience } = useContext();

  const fetch = async (mediaItemId: string): Promise<void> => {
    try {
      loading.value = true;

      const { data } = await axios.get(`${$amplience.mediaItemUrl}${mediaItemId}.json`, {
        params: {
          metadata: true,
          protocol: 'https',
        },
      });

      image.value = data;
      metadata.value = image?.value?.metadata;
    } catch (error) {
      Logger.error('Cannot get Media Set from Amplience.', error.message);
    }
  };

  return {
    image,
    metadata,
    fetch,
  };
}
